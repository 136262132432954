.pelecard-page__order {
  padding: 30px;
  min-height: 200px;
}

.pelecard-page__title {
  font-weight: 600;
  text-align: center;
}

.pelecard-page__info {
  text-align: center;
  margin: 20px 30px;
}

.pelecard-page__cardsList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.pelecard-page__card {
  padding: 40px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
}

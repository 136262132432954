/* --------------------------------for web footer--------------------------------------- */

.webFooter {
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 0;
  padding: 0 7%;
  margin: 10vh 0 4vh;
  width: 100%;
}

.footerSquare {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 30%;
  /*min-width: 205px;*/
}

.webFooter .footerSquare svg {
  font-size: 64px;
  fill: var(--primary);
  background: #f1f1f3;
  border-radius: 10px;
  padding: 12px;
}

.webFooter .footerSquare:nth-child(2) svg {
  padding: 14px;
}

.footerSquareH {
  direction: rtl;
  font-size: 24px;
  color: #183b56;
}

.footerSquareP {
  font-size: 17px;
  direction: rtl;
  padding: 7% 0 5%;
}

.footerSquareDetail {
  font-size: 13px;
  color: var(--primary);
  position: absolute;
  bottom: -23%;
  padding-bottom: 4vh;
}

.footerSquareImg {
  width: 19%;
  min-width: 40px;
  max-width: 80px;
  margin: 6% 0 9%;
}

.footerSquare:last-child .footerSquareDetail {
  font-size: 17px;
}

/*This is for Side bar buttons*/
.customizer.open {
  right: 0;
}

.border-left-blue-grey.border-left-lighten-4 {
  border-left: 1px solid #cfd8dc !important;
}

#b24-widget0 {
  height: 0 !important;
}

.customizer {
  position: fixed;
  z-index: 11;
  right: -100vw;
  bottom: 0;
  width: 428px;
  height: 100vh;
  height: 100dvh;
  padding: 0;
  -webkit-transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  -moz-transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  -o-transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  background-color: #fff;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.customizer a.customizer-close {
  color: #000;
}

.customizer .customizer-close {
  position: absolute;
  z-index: 10;
  top: 10px;
  right: 10px;
  width: auto;
  padding: 7px;
}

.customizer a.customizer-toggle {
  display: block;
  color: #6967ce;
}

.customizer .customizer-toggle {
  line-height: 36px;
  position: absolute;
  top: 35%;
  left: -54px;
  width: 54px;
  cursor: pointer;
  text-align: center;
  border-radius: 8px 0 0 8px;
  color: var(--primary);
  background-color: var(--primary);
}

.customizer .customizer-toggle a {
  width: 100%;
  display: flex;
  margin: 15px;
}

.customizer .customizer-content {
  height: 90%;
  overflow: scroll;
}

/*This is for Side bar buttons*/

/* this is for sidebar chat img */
.webMainChat {
  width: 100%;
  display: flex;
  margin: 15px;
}

.webFooter .customizer-content {
  margin-top: 11vh;
}

.webFooter .customizer-toggle svg {
  fill: white;
  font-size: 24px;
}

/* --------------------------------for mobile footer--------------------------------------- */

.footer {
  background-color: white;
  /* var(--primary); */
  width: 100%;
  max-width: 428px;
  padding: 5px 15px;
  position: fixed;
  bottom: 0;
  box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
  /* font-size: 14px; */

  /* max-width: 428px; */
  z-index: 11;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  height: 60px;
  /* height: 110px;
  box-shadow: 0px -20px 77px -15px rgba(0, 0, 0, 0.13);
  border-radius: 40px 40px 0px 0px; */
}

.footer .sosAndGray {
  display: flex;
  align-items: end;
  width: 100%;
}

.footer .sosAndGray > a {
  margin-right: 36px;
}

.footer .homeIconLink {
  /* background: var(--secondry); */
  border-radius: 50%;
  padding: 2px 7px 7px;
}

.footer .homeIconLink svg {
  fill: white;
}

.footer .grayIcons {
  display: contents;
}

.webChatImg {
  height: 22px;
  width: 22px;
}

.footer .grayIcon {
  height: 22px;
  width: 22px;
}

.footer .grayIconLink {
  color: var(--secondary);
}

.footer .accentIconLink {
  color: var(--primary);
  cursor: pointer;
  order: 1;
}

.footer .grayIconLink > svg {
  color: inherit;
  font-size: 27px;
}

.footer .grayIconLinkActive > svg {
  color: inherit;
  font-size: 30px;
}

.footer .mainChat {
  background: var(--primary);
  border: 3px solid #fff;
  width: 70px;
  right: 15px;
  bottom: 5px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 70px;
  align-items: center;
  border-radius: 50%;
  flex: none;
  cursor: pointer;
  color: var(--header-text);
}

.footer .mainChat .white {
  /* color: white; */
  padding-bottom: 5px;
}

.footer .chat {
  width: 36px;
  height: 36px;
}

.grayIconTitle {
  color: inherit;
  font-size: 12px;
}

.grayIconTitle__contrast {
  color: var(--header-text);
}

.grayIconLink {
  text-align: center;
  width: 30%;
  border-bottom: 5px solid white;
  /* var(--primary); */
}

.chosen {
  border-bottom: 5px solid var(--primary);
}

@media only screen and (max-width: 500px) {
  .customizer {
    width: 100%;
  }
}

@media only screen and (max-width: 375px) {
  .footer {
    padding: 5px 5px;
  }
}

@media only screen and (max-width: 355px) {
  .grayIconTitle {
    font-size: 10px;
  }
}

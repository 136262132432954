.paymentHestoryTemplate {
  padding: 20px 0;
}

.paymentHestoryCards {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  padding-top: 30px;
}

.paymentHestoryTemplate .cartProduct-box__img {
  background-position: top;
}

/* @media only screen and (max-width: 991px) { */
/* .paymentHestoryCards {
  grid-template-columns: 1fr;
} */

/* } */

.cartProduct-box__metadata {
  font-size: 10px;
  font-weight: 400;
}

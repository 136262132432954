.rules {
  text-align: right;
  overflow-y: scroll;
  height: 65vh;
}

.rules ol {
  padding-right: 20px;
}

.rules ul {
  list-style-type: disc !important;
  padding-right: 20px;
}

.rules li {
  margin-top: 20px;
}

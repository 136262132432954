.loadMoneyTemplate {
  padding: 10px 0;
}

.loadMoneyCard {
  width: 100%;
  margin: 50px auto 0;
  padding: 40px 22px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgb(42 134 159 / 10%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  max-width: 450px;
}

.loadMoneyCard svg {
  /* width: 40px; */
  /* height: 40px; */
  fill: var(--primary);
}

.loadMoneyCard-btn {
  margin-top: 30px;
}

.loadMoneyCard-btn button {
  max-width: 270px;
  margin: 0 auto;
  width: 200px;
  padding: 15px 20px !important;
  font-size: 16px !important;
  letter-spacing: 1px;
}

.loadMoneyCard-text {
  color: #888;
  text-transform: capitalize;
  font-size: 20px;
  line-height: 22px;
  font-weight: 500;
  margin: 30px 0;
}

.loadMoneyCard-price {
  /* font-size: 40px; */
  font-weight: 700;
  line-height: 46px;
}

.loadMoneyCard-price input {
  border: 0;
  width: 200px;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  line-height: 46px;
}
